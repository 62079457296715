navcontainer {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    
    
    header {
        background-color: var(--nimbus-white);
        background: linear-gradient(180deg, rgba(250,250,250,1) 85%, rgba(250,250,250,0) 100%); 
        // outline-color: var(--nimbus-black);
        // outline-width: 3px;
        // outline-style: solid;
        // outline-offset: -3px;
        height: 100px;
        
        nav {

            figure {
                img {
                    cursor: pointer;
                }

                .mobile-nav-menu-icon {
                    width: 48px;
                    position: absolute;
                    top: 26px;
                    right: 24px;
                }
                
            }
            
            .text-logo {
                // display: none;
                // margin: auto;
                // position: absolute;
                // display: flex;
                // width: 400px;
                // left: 0;
                // text-align: center;
                // height: 51px;
                
                img {
                    padding: 32px 24px;
                    height: 36px;
                    // margin: auto;
                    // padding-right: 20px;
                }
            }
            ul {
                display: none;
                justify-content: end;
                align-items: center;
                min-height: 50px;
                
                li {
                    list-style-type: none;
                }
                
            }
        }
    }
    
    aside {
        float: right;
        height: inherit;
        width: 100%;
        height: 100vh;
        background-color: var(--nimbus-white);
        
        ul {
            list-style-type: none;
            padding-left: 20px;
            li {
                padding: 0;
                a {
                    padding: 15px 0;
                    color: var(--nimbus-blue-text);
                    text-decoration: none;
                    font-weight: 400;
                    font-size: var(--button-text-size);
                    letter-spacing: 2px;
                    display: block;
                }
            }
        }
    }

    // dropdown closed class 
    // .hide {
    //     display: none;
    // }
    // tiny mobile (under 370px)
    @media screen and (min-width: 370px) {
        header {
            nav {
                ul {
                    figure {
                        margin-right: 0;
                    }
                }
            }
        }

    }

    // desktop view - remove the mobile menu and style the desktop menu
    @media screen and (min-width: 875px) {
        header {
            nav {
                margin: 0 100px;
                margin-right: 0;

                figure {
                    .mobile-nav-menu-icon {
                        display: none;
                    }
                }
                .text-logo {
                    display: none;
                    margin-right: 30px;
                    position: absolute;
                    display: flex;
                    width: 400px;
                    left: 0;
                    text-align: center;
                    height: 51px;
    

                }

                ul {
                    display: flex;
                    padding: 25px 24px 0;
                    // padding-right: 24px;
                    li {
                        padding: 0 10px;
                        a {
                            color: var(--nimbus-blue-text);
                            text-decoration: none;
                            font-weight: var(--regular-text-weight);
                            text-transform: capitalize;
                            font-size: var(--button-text-size);
                            letter-spacing: 2px;
                            display: inline;
                        }
                    }
                }
            }
        }
        aside {
            display: none;
        }
    }
}