// mixins
// gradient text effect for title 
@mixin gradient-text {
    background: linear-gradient(90deg, #1481c5, #4ca8da, #1481c5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    animation: shimmer 20s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 0px center;
    }
    100% {
      background-position: 400px center;
    }
  }

#what-we-do {
    .what-we-do-title-copy-and-gif {
        h2 {
            padding-top: var(--mobile-component-top-padding);
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 3px;
            font-weight: 600;
            color: var(--nimbus-blue);
            @include gradient-text;
        }
        p, ul { 
            color: var(--nimbus-black);
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            span {
                color: var(--nimbus-blue-text);
            }
        }
        p {
            padding-top: var(--mobile-component-top-padding);
        }
        ul {
            padding-top: 16px;
            // vertical-align: middle;
        }
        
        li {
            background: url("./assets/check-icon.svg") no-repeat 0px 3px;
            list-style-type: none;
            padding-left: 24px;
            color: var(--nimbus-blue-text);
        }
    }
    .what-we-do-image{
        padding-top: var(--mobile-component-top-padding);
        text-align: center;
        img {
            width: 100%;
            max-width: 300px;
        }
    }
    .what-we-do-copy-and-gif {
        @media screen and (min-width: 600px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .what-we-do-copy {
                max-width: 350px;
            }
        }
    }
    .what-we-do-title-copy-and-gif:nth-of-type(2) {
        .what-we-do-copy-and-gif {
        @media screen and (min-width: 600px) {
            flex-direction: row-reverse;
        }
    }
    }
}