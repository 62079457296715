// mixins
// gradient text effect for title 
@mixin gradient-text {
    background: linear-gradient(90deg, #1481c5, #4ca8da, #1481c5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    animation: shimmer 20s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 0px center;
    }
    100% {
      background-position: 400px center;
    }
  }
#hero {
    // min-height: 100vh;
    // display: grid;

    section {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        h1 {
            // margin-bottom: 10px;
            padding-top: var(--mobile-component-top-padding);
            font-size: 36px;
            letter-spacing: 1px;
            line-height: 40px;
            font-weight: 600;
        }
        
        p {
            padding-top: var(--mobile-component-top-padding);
            max-width: 300px;
            letter-spacing: 1px;
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
        }

        span {
            color: var(--nimbus-blue);
            @include gradient-text;
        }
        
        button:hover {
            background: #222222 !important;
        }
    }
    section:nth-child(1) {
        margin-top: 75px;
    }
    
    .hero-copy-gif-and-cta {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
        }
    }
    .hero-copy-and-cta {
        
        a {
            display: inline-block;
            margin-top: var(--mobile-component-top-padding);
            color: var(--nimbus-white);
            background: var(--nimbus-blue);
            padding: 12px 20px;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 1px;
            text-decoration: none;
        }
    }
    .hero-gif  {
            padding-top: var(--mobile-component-top-padding);
            text-align: center;
            img {
                max-width: 400px;
                width: 100%;
            }
        }
    // hide this section in mobile
    .services-previews {
        display: none;
    }
    .services-preview-container {
        // padding: 0 10vw;
        color: var(--nimbus-blue-text);
        figure {
            padding-top: var(--mobile-component-top-padding);
            display: flex;
            align-items: center;
            figcaption {
                padding-left: 24px;
                font-weight: 500;
                letter-spacing: 2px;
                font-size: 16px;
            }
        }
        p {
            width: 200px;
        }
    }
    .services-preview-container:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        align-items: end;
        figure {
            justify-content: end;
        }
        p{
            text-align: right;
        }
    }

      
    
    @media screen and (min-width: 400px) {
        h1 {
            line-height: 70px;
            font-size: 36px;
        }

        h2 {
            font-size: 20px;
            line-height: 30px;
        }
    }

    @media screen and (min-width: 840px) {

        .hero-cta {
            justify-content: start;
            padding-top: 100px;
        }

        .hero-gif  {
            img {
                max-width: 550px;
            }
        }

        section {
            h1 {
                margin-bottom: 25px;
                line-height: 70px;
                font-size: 48px;
            }

            h2 {
                font-size: 30px;
                line-height: 30px;
                max-width: 600px;
            }            
            button {
                padding: 15px;
                font-size: 20px;

            }
        }

    }

    @media screen and (min-width: 1024px) {
        section:nth-child(1) {
            margin-top: 100px;
        }
    }
}