#insights {
    .insights-cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            padding-top: var(--mobile-component-top-padding);
            font-size: 16px;
            // text-align: center;
            color: var(--nimbus-blue-text);
        }
        h2 {
            padding-top: var(--mobile-component-top-padding);
            // text-align: center;
            color: var(--nimbus-blue-text);
        }

        a {
            text-align: center;
            display: inline-block;
            margin-top: var(--mobile-component-top-padding);
            color: var(--nimbus-white);
            background: var(--nimbus-blue);
            padding: 12px 20px;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 1px;
            text-decoration: none;
        }
    }
}