// general 
body {
    font-family: var(--font-primary);
    font-weight: var(--extra-light-text-weight);
    color: var(--nimbus-black);
  }
// remove default padding and margin
h1, h2, p, ul, ol, li {
  padding: 0;
  margin: 0;
}