footer {
    // position: absolute;
    width: 100%;
    // bottom: 0;
    display: inline-block;

    .footer {
        background: var(--nimbus-white);
        padding: 30px 0px;
        font-family: 'Kanit', sans-serif;
        text-align: center;
    }

    .footer .row {
        width: 100%;
        margin: 1% 0%;
        padding: 0.6% 0%;
        color: var(--nimbus-blue-text);
        font-size: 0.8em;
    }

    .footer .row a {
        text-decoration: none;
        color: var(--nimbus-blue-text);
        transition: 0.5s;
        font-family: var(--font-primary);
        font-weight: var(--regular-text-weight);
    }

    .footer .row a:hover {
        color: #2b2b2b;
    }

    .footer .row ul {
        width: 100%;
    }

    .footer .row ul li {
        display: inline-block;
        margin: 0px 30px;
    }

    .footer .row a i {
        font-size: 2em;
        margin: 0% 1%;
    }

    @media (max-width:720px) {
        .footer {
            text-align: left;
            padding: 5%;
        }

        .footer .row ul li {
            display: block;
            margin: 10px 0px;
            text-align: left;
        }

        .footer .row a i {
            margin: 0% 3%;
        }
    }
    p a {
        font-size: 16px;
    }
}