#who-we-are {
    margin-top: 50px;
    .who-we-are-title-copy-and-gif {
        h2 {
            padding-top: var(--mobile-component-top-padding);
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 3px;
            font-weight: 600;
        }
        span {
            color: var(--nimbus-blue);
        }
        p, ul { 
            color: var(--nimbus-black);
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            span {
                // color: var(--nimbus-blue-text);
            }
        }
        p {
            padding-top: var(--mobile-component-top-padding);
        }
        ul {
            padding-top: 12px;
            padding-left: 16px;
            // color: var(--nimbus-blue-text);
        }
    }
    .who-we-are-gif {
        padding-top: var(--mobile-component-top-padding);
        text-align: center;
        img {
            width: 100%;
            max-width: 350px;
        }
    }
    .who-we-are-copy-and-gif {
        @media screen and (min-width: 600px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .who-we-are-copy {
                max-width: 350px;
            }
        }
    }
}