@import "https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
body {
  font-family: var(--font-primary);
  font-weight: var(--extra-light-text-weight);
  color: var(--nimbus-black);
}

h1, h2, p, ul, ol, li {
  margin: 0;
  padding: 0;
}

navcontainer {
  z-index: 2;
  width: 100%;
  position: fixed;
  top: 0;
}

navcontainer header {
  background-color: var(--nimbus-white);
  height: 100px;
  background: linear-gradient(#fafafa 85%, #fafafa00 100%);
}

navcontainer header nav figure img {
  cursor: pointer;
}

navcontainer header nav figure .mobile-nav-menu-icon {
  width: 48px;
  position: absolute;
  top: 26px;
  right: 24px;
}

navcontainer header nav .text-logo img {
  height: 36px;
  padding: 32px 24px;
}

navcontainer header nav ul {
  min-height: 50px;
  justify-content: end;
  align-items: center;
  display: none;
}

navcontainer header nav ul li {
  list-style-type: none;
}

navcontainer aside {
  float: right;
  height: inherit;
  width: 100%;
  height: 100vh;
  background-color: var(--nimbus-white);
}

navcontainer aside ul {
  padding-left: 20px;
  list-style-type: none;
}

navcontainer aside ul li {
  padding: 0;
}

navcontainer aside ul li a {
  color: var(--nimbus-blue-text);
  font-weight: 400;
  font-size: var(--button-text-size);
  letter-spacing: 2px;
  padding: 15px 0;
  text-decoration: none;
  display: block;
}

@media screen and (min-width: 370px) {
  navcontainer header nav ul figure {
    margin-right: 0;
  }
}

@media screen and (min-width: 875px) {
  navcontainer header nav {
    margin: 0 0 0 100px;
  }

  navcontainer header nav figure .mobile-nav-menu-icon {
    display: none;
  }

  navcontainer header nav .text-logo {
    width: 400px;
    text-align: center;
    height: 51px;
    margin-right: 30px;
    display: flex;
    position: absolute;
    left: 0;
  }

  navcontainer header nav ul {
    padding: 25px 24px 0;
    display: flex;
  }

  navcontainer header nav ul li {
    padding: 0 10px;
  }

  navcontainer header nav ul li a {
    color: var(--nimbus-blue-text);
    font-weight: var(--regular-text-weight);
    text-transform: capitalize;
    font-size: var(--button-text-size);
    letter-spacing: 2px;
    text-decoration: none;
    display: inline;
  }

  navcontainer aside {
    display: none;
  }
}

@keyframes shimmer {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 400px;
  }
}

#hero section h1 {
  padding-top: var(--mobile-component-top-padding);
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
}

#hero section p {
  padding-top: var(--mobile-component-top-padding);
  max-width: 300px;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

#hero section span {
  color: var(--nimbus-blue);
  background: linear-gradient(90deg, #1481c5, #4ca8da, #1481c5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: 20s linear infinite shimmer;
}

#hero section button:hover {
  background: #222 !important;
}

#hero section:nth-child(1) {
  margin-top: 75px;
}

#hero .hero-copy-gif-and-cta {
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 600px) {
  #hero .hero-copy-gif-and-cta {
    flex-direction: row;
    align-items: center;
  }
}

#hero .hero-copy-and-cta a {
  margin-top: var(--mobile-component-top-padding);
  color: var(--nimbus-white);
  background: var(--nimbus-blue);
  letter-spacing: 1px;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
  display: inline-block;
}

#hero .hero-gif {
  padding-top: var(--mobile-component-top-padding);
  text-align: center;
}

#hero .hero-gif img {
  max-width: 400px;
  width: 100%;
}

#hero .services-previews {
  display: none;
}

#hero .services-preview-container {
  color: var(--nimbus-blue-text);
}

#hero .services-preview-container figure {
  padding-top: var(--mobile-component-top-padding);
  align-items: center;
  display: flex;
}

#hero .services-preview-container figure figcaption {
  letter-spacing: 2px;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 500;
}

#hero .services-preview-container p {
  width: 200px;
}

#hero .services-preview-container:nth-of-type(2) {
  flex-direction: column;
  align-items: end;
  display: flex;
}

#hero .services-preview-container:nth-of-type(2) figure {
  justify-content: end;
}

#hero .services-preview-container:nth-of-type(2) p {
  text-align: right;
}

@media screen and (min-width: 400px) {
  #hero h1 {
    font-size: 36px;
    line-height: 70px;
  }

  #hero h2 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (min-width: 840px) {
  #hero .hero-cta {
    justify-content: start;
    padding-top: 100px;
  }

  #hero .hero-gif img {
    max-width: 550px;
  }

  #hero section h1 {
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 70px;
  }

  #hero section h2 {
    max-width: 600px;
    font-size: 30px;
    line-height: 30px;
  }

  #hero section button {
    padding: 15px;
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  #hero section:nth-child(1) {
    margin-top: 100px;
  }
}

#who-we-are {
  margin-top: 50px;
}

#who-we-are .who-we-are-title-copy-and-gif h2 {
  padding-top: var(--mobile-component-top-padding);
  letter-spacing: 3px;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

#who-we-are .who-we-are-title-copy-and-gif span {
  color: var(--nimbus-blue);
}

#who-we-are .who-we-are-title-copy-and-gif p, #who-we-are .who-we-are-title-copy-and-gif ul {
  color: var(--nimbus-black);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

#who-we-are .who-we-are-title-copy-and-gif p {
  padding-top: var(--mobile-component-top-padding);
}

#who-we-are .who-we-are-title-copy-and-gif ul {
  padding-top: 12px;
  padding-left: 16px;
}

#who-we-are .who-we-are-gif {
  padding-top: var(--mobile-component-top-padding);
  text-align: center;
}

#who-we-are .who-we-are-gif img {
  width: 100%;
  max-width: 350px;
}

@media screen and (min-width: 600px) {
  #who-we-are .who-we-are-copy-and-gif {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  #who-we-are .who-we-are-copy-and-gif .who-we-are-copy {
    max-width: 350px;
  }
}

#what-we-do .what-we-do-title-copy-and-gif h2 {
  padding-top: var(--mobile-component-top-padding);
  letter-spacing: 3px;
  color: var(--nimbus-blue);
  background: linear-gradient(90deg, #1481c5, #4ca8da, #1481c5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  animation: 20s linear infinite shimmer;
}

#what-we-do .what-we-do-title-copy-and-gif p, #what-we-do .what-we-do-title-copy-and-gif ul {
  color: var(--nimbus-black);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

#what-we-do .what-we-do-title-copy-and-gif p span, #what-we-do .what-we-do-title-copy-and-gif ul span {
  color: var(--nimbus-blue-text);
}

#what-we-do .what-we-do-title-copy-and-gif p {
  padding-top: var(--mobile-component-top-padding);
}

#what-we-do .what-we-do-title-copy-and-gif ul {
  padding-top: 16px;
}

#what-we-do .what-we-do-title-copy-and-gif li {
  color: var(--nimbus-blue-text);
  background: url("check-icon.a1630a4d.svg") 0 3px no-repeat;
  padding-left: 24px;
  list-style-type: none;
}

#what-we-do .what-we-do-image {
  padding-top: var(--mobile-component-top-padding);
  text-align: center;
}

#what-we-do .what-we-do-image img {
  width: 100%;
  max-width: 300px;
}

@media screen and (min-width: 600px) {
  #what-we-do .what-we-do-copy-and-gif {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  #what-we-do .what-we-do-copy-and-gif .what-we-do-copy {
    max-width: 350px;
  }

  #what-we-do .what-we-do-title-copy-and-gif:nth-of-type(2) .what-we-do-copy-and-gif {
    flex-direction: row-reverse;
  }
}

#about-us .about-us-title h2, #about-us .about-us-title p {
  color: var(--nimbus-blue);
  padding-top: var(--mobile-component-top-padding);
}

#about-us .about-us-title p {
  color: var(--nimbus-blue-text);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

#about-us .about-us-title p a {
  color: var(--nimbus-blue-text);
}

#about-us .about-us-accordions .accordion {
  margin-top: var(--mobile-component-top-padding);
  color: var(--nimbus-blue-text);
}

#about-us .about-us-accordions .accordion .content, #about-us .about-us-accordions .accordion .label {
  padding-top: calc(var(--mobile-component-top-padding) / 2);
  color: var(--nimbus-blue-text);
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

#about-us .about-us-accordions .accordion .content a, #about-us .about-us-accordions .accordion .label a {
  color: var(--nimbus-blue-text);
}

#about-us .about-us-accordions .accordion .label {
  margin-left: -15px;
  font-weight: 500;
}

#about-us .about-us-accordions .accordion .content table {
  border-spacing: 5vw 24px;
}

@media screen and (min-width: 840px) {
  #about-us .about-us-accordions .accordion .content table {
    border-spacing: 50px 24px;
  }
}

@media screen and (min-width: 1024px) {
  #about-us .about-us-accordions .accordion .content table {
    border-spacing: 83px 24px;
  }
}

#about-us .about-us-accordions .accordion .content th, #about-us .about-us-accordions .accordion .content td {
  vertical-align: top;
  word-spacing: 1px;
  text-align: left;
}

#about-us .about-us-accordions .accordion .content th {
  color: var(--nimbus-blue-text);
  background: linear-gradient(90deg, #1481c5, #4ca8da, #1481c5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: 20s linear infinite shimmer;
}

#about-us .about-us-accordions .accordion .content tbody {
  color: var(--nimbus-black);
}

#about-us .about-us-accordions .accordion .content tr {
  cursor: pointer;
}

#about-us .about-us-accordions .accordion .content tr td:nth-child(3) {
  color: #fff;
  vertical-align: middle;
  text-align: center;
}

#about-us .about-us-accordions .accordion .content tr td:nth-child(3) a {
  width: 100%;
  line-height: 40px;
  display: block;
}

#about-us .about-us-accordions .accordion .label:before {
  content: "+";
  color: var(--nimbus-blue-text);
  font-size: 30px;
  position: relative;
  top: 50%;
  right: -85vw;
}

@media screen and (min-width: 600px) {
  #about-us .about-us-accordions .accordion .label:before {
    right: -550px;
  }
}

@media screen and (min-width: 840px) {
  #about-us .about-us-accordions .accordion .label:before {
    right: -620px;
  }
}

@media screen and (min-width: 1024px) {
  #about-us .about-us-accordions .accordion .label:before {
    right: -775px;
  }
}

#about-us .about-us-accordions .accordion .content {
  height: 0;
  color: var(--nimbus-black);
  text-align: justify;
  max-width: 780px;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  transition: all .5s;
  position: relative;
  overflow: hidden;
}

#about-us .about-us-accordions .accordion .container.active .content {
  height: -moz-fit-content;
  height: fit-content;
}

#about-us .about-us-accordions .accordion .container.active .label:before {
  content: "-";
  font-size: 30px;
}

#insights .insights-cta {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#insights .insights-cta h3 {
  padding-top: var(--mobile-component-top-padding);
  color: var(--nimbus-blue-text);
  font-size: 16px;
}

#insights .insights-cta h2 {
  padding-top: var(--mobile-component-top-padding);
  color: var(--nimbus-blue-text);
}

#insights .insights-cta a {
  text-align: center;
  margin-top: var(--mobile-component-top-padding);
  color: var(--nimbus-white);
  background: var(--nimbus-blue);
  letter-spacing: 1px;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
  display: inline-block;
}

footer {
  width: 100%;
  display: inline-block;
}

footer .footer {
  background: var(--nimbus-white);
  text-align: center;
  padding: 30px 0;
  font-family: Kanit, sans-serif;
}

footer .footer .row {
  width: 100%;
  color: var(--nimbus-blue-text);
  margin: 1% 0%;
  padding: .6% 0%;
  font-size: .8em;
}

footer .footer .row a {
  color: var(--nimbus-blue-text);
  font-family: var(--font-primary);
  font-weight: var(--regular-text-weight);
  text-decoration: none;
  transition: all .5s;
}

footer .footer .row a:hover {
  color: #2b2b2b;
}

footer .footer .row ul {
  width: 100%;
}

footer .footer .row ul li {
  margin: 0 30px;
  display: inline-block;
}

footer .footer .row a i {
  margin: 0% 1%;
  font-size: 2em;
}

@media (max-width: 720px) {
  footer .footer {
    text-align: left;
    padding: 5%;
  }

  footer .footer .row ul li {
    text-align: left;
    margin: 10px 0;
    display: block;
  }

  footer .footer .row a i {
    margin: 0% 3%;
  }
}

footer p a {
  font-size: 16px;
}

:root {
  --font-primary: "Kanit", sans-serif;
  --text-base-size: 20px;
  --extra-light-text-weight: 200;
  --light-text-weight: 300;
  --regular-text-weight: 400;
  --button-text-size: 12pt;
  --font-heading-color: var(--nimbus-black);
  --font-heading-color-inverted: #7e7e7e;
  --font-secondary-color: #b6b6b6;
  --font-link-color: #59a2f6;
  --font-linkedin-link-color: #0a64c0;
  --margin-bottom: 20px;
  --fade-b-to-w: linear-gradient(to bottom, #06060600, #060606);
  --fade-center: radial-gradient(#0f0f0f, #06060680);
  --background: var(--nimbus-white);
  --max-box-width: 800px;
  --max-title-width: 900px;
  --nimbus-white: #fafafa;
  --nimbus-black: #0f0f0f;
  --nimbus-blue: #1481c5;
  --nimbus-blue-text: #006bad;
  --mobile-component-top-padding: 30px;
  --medium-bp: 865px;
  --large-bp: 1121px;
  --extra-large-bp: 1440px;
}

html {
  font-size: var(--text-base-size);
}

body {
  background: var(--background);
  overflow-x: hidden;
}

article {
  padding: 0 24px;
}

@media screen and (min-width: 600px) {
  article {
    padding: 0;
  }

  article section {
    max-width: 575px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 840px) {
  article section {
    max-width: 650px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  article section {
    max-width: 800px;
    margin: 0 auto;
  }
}

button:hover, button:focus {
  background: #e2e2e2 !important;
}

button:focus {
  outline: 1px solid var(--nimbus-black);
  outline-offset: -4px;
}

button:active {
  transform: scale(.99);
}

ul, figure {
  margin: 0;
  padding: 0;
}

.hide {
  display: none !important;
}

.opaqueHeader {
  background-color: var(--nimbus-white) !important;
}

.disableScroll {
  overflow: hidden;
}

/*# sourceMappingURL=index.4b515769.css.map */
