// mixins
// gradient text effect for title 
@mixin gradient-text {
    background: linear-gradient(90deg, #1481c5, #4ca8da, #1481c5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    animation: shimmer 20s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 0px center;
    }
    100% {
      background-position: 400px center;
    }
  }
#about-us {
    .about-us-title {
        h2,p {
            color: var(--nimbus-blue);
            padding-top: var(--mobile-component-top-padding);
        }

        p { 
            color: var(--nimbus-blue-text);
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            a {
            color: var(--nimbus-blue-text);
            }
        }
    }
    .about-us-accordions {
        // standard styles for open accordion without buttons
        .accordion {
            // width: 800px;
            margin-top: var(--mobile-component-top-padding);
            color: var(--nimbus-blue-text);
            // background-color: white;
            // padding: 45px 45px;
            
            .content, .label { 
                    padding-top: calc(var(--mobile-component-top-padding) / 2);
                    color: var(--nimbus-blue-text);
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 300;
                    a {
                        color: var(--nimbus-blue-text);
                    }
                }
                .label {
                    font-weight: 500;
                    margin-left: -15px;
                }
                .content {
                table {
                    border-spacing: 5vw 24px;
                    @media screen and (min-width: 840px)  {
                        border-spacing: 50px 24px;
                    }
                    @media screen and (min-width: 1024px)  {
                        border-spacing: 83px 24px;
                    }
                }
                    th, td {
                        vertical-align: top;
                        word-spacing: 1px;
                        text-align: left;
                    }
                    th {
                        color: var(--nimbus-blue-text);
                        @include gradient-text;
                    }
                    tbody {
                        color: var(--nimbus-black);
                    }
                    tr {
                        cursor: pointer;
                    }
                    tr td:nth-child(3) {
                        // border: 1px solid var(--nimbus-blue) 5px;
                        // background-color: var(--nimbus-blue);
                        color: white;
                        vertical-align: middle;
                        text-align: center;
                        a {
                            width: 100%;
                            line-height: 40px;
                            display: block;
                        }
                      }
                }
                
            }
        /* Positions the plus sign 5px from the right. Centers it using the transform property. */

        .accordion .label::before {
            content: '+';
            color: var(--nimbus-blue-text);
            position: relative;
            top: 50%;
            right: -85vw;
            font-size: 30px;
            @media screen and (min-width: 600px)  {
                right: -550px;
            }
            @media screen and (min-width: 840px)  {
                right: -620px;
            }
            @media screen and (min-width: 1024px)  {
                right: -775px;
            }
        }
        
        /* Hides the content (height: 0), decreases font size, justifies text and adds transition */
        
        .accordion .content {
            position: relative;
            // background: white;
            height: 0;
            font-size: 18px;
            color: var(--nimbus-black);
            font-weight: 300;
            line-height: 30px;
            text-align: justify;
            max-width: 780px;
            overflow: hidden;
            transition: 0.5s;
        }
        
        
        /* Unhides the content part when active. Sets the height */

        .accordion .container.active .content {
            height: fit-content;
        }
        
        /* Changes from plus sign to negative sign once active */
        
        .accordion .container.active .label::before {
            content: '-';
            font-size: 30px;
        }
  
    }
}