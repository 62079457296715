//fonts
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// imported sass files
@import 'typography', 'header', 'hero', 'who-we-are', 'what-we-do', 'about-us', 'insights', 'footer';
// @import 'coming-soon-landing';

:root {
  --font-primary: "Kanit", sans-serif;
  --text-base-size: 20px;
  --extra-light-text-weight: 200;
  --light-text-weight: 300;
  --regular-text-weight: 400;
  --button-text-size: 12pt;
  --font-heading-color: var(--nimbus-black);
  --font-heading-color-inverted: #7e7e7e;
  --font-secondary-color: #b6b6b6;
  --font-link-color: #59a2f6;
  --font-linkedin-link-color: #0a64c0;
  --margin-bottom: 20px;
  --fade-b-to-w : linear-gradient(to bottom, rgba(6,6,6,0), rgba(6,6,6, 1));
  --fade-center : radial-gradient(#0f0f0f, rgba(6,6,6,.5));
  --background: var(--nimbus-white);
  --max-box-width: 800px;
  --max-title-width: 900px;
  // colour variables
  --nimbus-white: #FAFAFA;
  --nimbus-black: #0F0F0F;
  --nimbus-blue: #1481C5;
  --nimbus-blue-text: #006BAD;
  // layout variables
  --mobile-component-top-padding: 30px;
  // breakpoints
  --medium-bp: 865px;
  --large-bp: 1121px;
  --extra-large-bp: 1440px;
}

html {
  font-size: var(--text-base-size);
}

body {
  background: var(--background);
  overflow-x: hidden;
  // height: 100%;
}

article {
  // max-width: 2000px;
  padding: 0 24px;
  @media screen and (min-width: 600px) { 
  padding: 0;
  section {
    max-width: 575px;
    margin: 0 auto;
  }
  }
  @media screen and (min-width: 840px) { 
    section {
      max-width: 650px;
      margin: 0 auto;
    }
    }

    @media screen and (min-width: 1024px) { 
      section {
        max-width: 800px;
        margin: 0 auto;
      }
      }

  // @media screen and (min-width: 1440px) { 
  //   padding: 0 220px;
  //   }
  
}

// mixins
// gradient text effect for title 
@mixin gradient-text {
  background: linear-gradient(90deg, #1481c5, #4ca8da, #1481c5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  animation: shimmer 20s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: 400px center;
  }
}

// button a11y styles.
button:hover,
button:focus {
    background: #e2e2e2 !important;
}

button:focus {
    outline: 1px solid var(--nimbus-black);
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}

// weird css fixes 

ul, figure {
  margin: 0;
  padding: 0;
}

// javascript classes

// dropdown closed class 
.hide {
  display: none !important;
}

.opaqueHeader {
  background-color: var(--nimbus-white) !important;
}

// disable scrolling 
.disableScroll{
  overflow-y:hidden;
  overflow-x:hidden;
}
